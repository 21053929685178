$unitSize: 20px;
@import 'https://fonts.googleapis.com/css?family=Montserrat:wght@300';

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #222;
  color: #ccc;
  font-family: 'Montserrat', sans-serif;
  font-size: $unitSize;
  line-height: 1.75em;
  padding: $unitSize;
}

hr {
  clear: both;
	height: 1px;
	background-color: #999;
	border: none;
	width: 100%;
}

a[href] {
	color: #419178;
}

a[href]:hover {
	color: #b0d9cc;
}

img {
	max-width: 100%;
}

nav {

  ul {
    font-size: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding-left: 1vw;
    padding-right: 1vw;
    text-decoration: none;
  }

  a:hover {
    background-color: #15211d;
  }

  a.active {
    background-color: #15211d;
    color: #b0d9cc;
    cursor: default;
  }
}

#site-name {
  font-size: 30px; 
  line-height: 1em;
  padding-right: 2vw;
}

.max-fifty {
  width: 100%;
  height: auto;
  max-width: 50vw;
}

.align-left {
  float: left;
}

.align-right {
  float: right;
}

.large-intro {
  font-size: 4vw;
  line-height: 4vw;
}

.space-right {
  padding-right: $unitSize;
}

.subpage-intro {
  font-size: 3vw;
  line-height: 4vw;
  padding-left: 20vw;
  padding-right: 20vw;
  text-align: center;
}

.image-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $unitSize;
  line-height: 0px;
}
.image-grid > div > a > img {
  opacity: 0.8;
  transition: transform .2s;
}
.image-grid > div > a > img:hover {
  opacity: 1.0;
}

ul.image_nav {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(3, minmax(250px, 434px));
  gap: 20px;
  font-weight: 800;
  padding: 0;
}

.image_wrapper {
  position: relative;
}

ul.image_nav img {
  display: block;
}
ul.image_nav li {
  opacity: 0.8;
}
ul.image_nav li:hover {
  opacity: 1.0;
}

.overlay {
  position: absolute;
  background: rgba(30, 30, 30, 0.5);
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
}
.overlay p {
  color: #fff;
  font-size: 20px;
  margin: 0;
}



@media (min-width: 1921px) {
  body {
    font-size: 24px;
  }
}
